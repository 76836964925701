
.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px; /* Increase the bottom margin */
}

.btn {
  margin-right: 5px; /* Reduced margin for smaller spacing */
  padding: 10px 3px; /* Further reduced padding for compact size */
  border: none;
  border-radius: 6px; /* Slightly smaller rounded corners */
  background-color: ""; /* Subtle background color */
  color: #333; /* Darker text color for contrast */
  cursor: pointer;
  font-size: 12px; /* Smaller font size for readability */
  transition: background-color 0.8s ease, transform 0.2s ease;
  margin-bottom: 5px; /* Reduced margin for vertical spacing */
  text-align: center; /* Center text for artistic balance */
  line-height: 1.4; /* Adjusted line height for mobile */
}

.btn:hover {
  background-color: #ccc; /* Lighter background on hover */
  transform: translateY(-2px); /* Slight vertical shift for depth */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for elegance */
}

/* Media query for small mobile screens */
@media (max-width: 350px) {
  .btn {
    margin-right: 0; /* Remove margin for small screens */
    width: 100%; /* Make the button full width */
  }
}

.btn.active {
  background-color: #fff; 
  color: #000000;
  border: 2px solid #FFA500; /* Orange border for active state */
}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px; /* Increase the top margin */
  background-color: #f8f8f8; /* Use a lighter background color */
  padding: 20px; /* Add padding for more spacing */
}


.circle {
  display: inline-block;
  width: 40px; /* Adjust the width and height as needed */
  height: 40px;
  border-radius: 50%; /* Makes it circular */
  border: 2px solid orange; /* Orange border */
  text-align: center;
  line-height: 40px; /* Center the text vertically */
  font-weight: bold;
  color: orange; /* Text color */
}

.nationality {
  font-family: "Your-Desired-Font", sans-serif; 
  color: black;
}


.font-stylish {
  font-family: 'Your-Desired-Font', sans-serif; /* Change to your desired font */
  color: #09009B; /* Dark blue color */
  /* Add any other font styling properties you prefer */
  
   /* Example: Uppercase text */
  /* Add more styles as needed */
}